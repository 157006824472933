import React, { Component } from 'react';
import { Intent, Position, Toast2, OverlayToaster } from '@blueprintjs/core';
import queryString from 'query-string';
import * as styles from './dans.module.less';
import API from '../../config';
import Logger from '../../models/logger';
import AxiosClient from '../../lib/AxiosClient';
import AppToaster from '../../lib/Toaster';

/**
 * An empty component to speed up new component creation
 */
class DANS extends Component {
  constructor(props) {
    super(props);

    /*
    Using query params to pass info into this component:
    uid = "User ID" = the ID of the user
     */
    const parsedQueryParams = queryString.parse(location.search);

    this.state = {
      showDANS: false, // By default, don't show anything as a security by obscurity measure
      userID: parsedQueryParams.uid,
      toasts: [],
      lastEvents: null,
    };

    /* Create our logger */
    this.logger = new Logger('DANS');

    /* Set up the toaster */
    this.toaster = AppToaster;
    this.refHandlers = {
      toaster: ref => this.toaster = ref,
    };
  }

  async componentDidMount() {
    if (this.state.userID && this.state.userID.length > 0) {
      await this.loadLatestActivity();
    } else {
      // We don't have enough data to show interface
      window.location = 'https://en.wikipedia.org/wiki/Madara_Rider';
    }
  }

  async loadLatestActivity() {
    try {
      const response = await AxiosClient.getInstance().post(`${API.API_URL}/2.0/dans/last-events`, {
        userID: this.state.userID,
      });

      const lastEvents = response.data;

      console.log(`latestEvents: ${JSON.stringify(lastEvents)}`);

      this.setState({
        lastEvents,
        showDANS: true,
      });
    } catch (err) {
      this.logger.error('DANS - loadLatestActivity', `Error loading last activity: ${err}`);

      this.toaster.show({
        message: `Error loading activity: ${err}`,
        icon: 'issue',
        intent: Intent.DANGER,
      });
    }
  }


  render() {
    return (
      <div className={styles.component}>
        {/* Toast component*/}
        <OverlayToaster position={Position.BOTTOM} ref={this.refHandlers.toaster}>
          {/* "Toasted!" will appear here after clicking button. */}
          {this.state.toasts.map(toast => <Toast2 {...toast} />)}
        </OverlayToaster>

        {/* We are not allowed to show the interface*/}
        {!this.state.showDANS && <div></div>}

        {/* We are allowed to show the interface*/}
        {this.state.showDANS && <div>
          <strong>DANS</strong>


          {this.state.lastEvents && <div className={styles.events}>

            {this.state.lastEvents.map(event => <div key={event.id} className={styles.event}>
              <div className={styles.dateTime}>
                {event.created_at}
              </div>
              <div className={styles.name}>
                {event.event_name}
              </div>
              <div className={styles.target}>
                {event.target_id}
              </div>
              <div className={styles.details}>
                {event.details ? JSON.stringify(event.details) : 'No details'}
              </div>
            </div>)}

          </div>}
        </div>}
      </div>
    );
  }
}

export default DANS;
