import React, { Component } from 'react';
import appLogo from '../../assets/images/logo/brandmark-green-200.png';
import * as styles from './loadingAnimation.module.less';

/**
 * Component displays a loading animations when we need it
 */
class LoadingAnimation extends Component {
  render() {
    return (
      <div className={styles.component}>
        <div className={styles.loader1}>
          <img src={appLogo}/>
        </div>
      </div>
    );
  }
}

export default LoadingAnimation;
