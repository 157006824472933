import React from 'react';
import * as styles from '../home.module.less';
import Config from '../../config';
import AuthService from '../../lib/AuthService';

/**
 * Component logs the user out
 */
class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      API_URL: Config.API_URL,
    };
  }

  render() {
    AuthService.getInstance().logout();

    return (
      <div className={styles.component}>
        {/* Logout*/}
      </div>
    );
  }
}


export default Logout;

