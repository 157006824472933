import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import * as styles from './activateCharge.module.less';
import AppConfig from '../../config';


class ShopifyActiveChargePage extends Component {
  constructor(props) {
    super(props);
    // console.log('StripeCheckoutPage constructor');

    const qsValues = queryString.parse(this.props.location.search);
    const chargeId = qsValues.charge_id;

    this.state = {
      chargeId,
    };
  }

  async componentDidMount() {
    /* Activate the charge  */
    try {
      // NB - using axios here directly, vs. as axios client. When this page is called from the Shopify Admin embedded view we don't have a session going
      // So we'll get an error. But we have the charge id so just do the post
      const response = await axios.post(`${AppConfig.API_URL}/1.0/billing/shopify/activate-recurring-charge/`, {
        chargeId: this.state.chargeId,
      });

      this.setState({
        activated: true,
        store: response.data.store,
      });

      setTimeout(() => {
        /* DEPRECATED - If here, we are all good, so send the user home. Use window.location for a hard, app-reloading redirect */
        // window.location = '/home';

        /* Redirect them back to the shopify admin */
        window.location = `https://admin.shopify.com/store/${this.state.store.replace('.myshopify.com', '')}`;
      }, 5000);
    } catch (err) {
      throw new Error(`Exception in activate-recurring-charge: ${err}`);
    }
  }


  render() {
    return (<div className={styles.component}>

      {this.state.activated && <div>
        Thank you for enabling billing for {this.state.store}
        <br/>
        <br/>
        Redirecting you to Mixtable in 5 seconds.
      </div>}

    </div>);
  }
}

export default ShopifyActiveChargePage;
