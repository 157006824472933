@import "../../styles/variables";

.component {
  background: @background-base;
  height: 100%;
  width: 100%;

  .pricingPlansContainer {
    margin: 0 auto 0 auto;
    width: 800px;

    h2 {
      margin: 0;
      padding: 50px 0 10px 5px;
      line-height: 1.0em;
      font-weight: 600;
    }

    .content {
      background: @white;
      border-radius: @border-radius;
      border: 1px solid @border-background-base;
      padding: 40px 20px 40px 20px;
    }

    .column {
      display: inline-block;
      vertical-align: top;
      width: 33%;
      padding-right: 3px;

      .headingCell {
        padding: 5px 0 5px 0;
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
      }

      .cell {
        padding: 10px 0 10px 0;
        border-bottom: @border-1;
        line-height: 1.5em;

        .checkmark {
          color: @foreground-primary;
          font-weight: 500;
          font-size: 20px;
          padding-left: 10px;
          text-align: left;
        }

        .featureBreakdown {
          padding-left: 20px;
        }
      }

      .planName {
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
      }

      .planPrice {
        padding: 15px 0 10px 0;

        .value {
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          padding-bottom: 5px;
          display: inline-block;
          vertical-align: top;
        }

        .qualifier {
          font-size: 0.8em;
          text-transform: none;
          display: inline-block;
          vertical-align: top;
          padding-left: 10px;
        }
      }

      .planCta {
        button {
          margin: 5px;
        }
      }
    }

    .footer {
      padding: 20px 0 0 0;
      text-align: center;
      opacity: 0.5;
    }
  }
}
