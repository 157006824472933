@import "../../../../styles/variables";

.component {
  margin-bottom: 50px;
  .workbooksCatalog {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px 30px;
    cursor: pointer;
    align-items: stretch;

    .workbookCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #eeeef2;
      border-radius: 8px;
      //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px 10px;


      .workbookCardHeader {
        display: flex;
        height: fit-content;
        //margin-bottom: 16px;
        //padding-right: 20px;
        color: @foreground-primary;

        .icon {
          margin: 5px 20px 5px 5px;
          font-size: 22px;
          color: @foreground-primary;
          opacity: 0.7;
        }

        .workbookName {
          font-weight: 600;
          color: @font-base-color;
          line-height: 26px;
        }
      }

      .workbookCardBody {
        flex-grow: 1;  /* Add this line */
        display: flex;  /* Add this line */
        flex-direction: column;  /* Add this line */
        justify-content: flex-start;
        padding-left: 47px;

        color: #797986;
        font-size: 11px;
        line-height: 1.5em;

        padding-bottom: 5px;
      }

      &:hover {
        //background: @background-base;
        //box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        box-shadow: 0 4px 30px rgba(48,48,54,.04);
        span {
          opacity: 1.0;
        }
      }
    }
  }
}
