@import "../../../styles/variables";

.component {
  .sidebar {
    background: @background-base;
    border-right: 1px solid @border-background-base;
    width: 240px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 10px 0 0 10px;

    .orgName {
      font-weight: 600;
      padding: 10px;
      margin-top: 7px;
    }

    .sidebarMenu {
      padding: 10px 0 0 0;

      .menuItem {
        cursor: pointer;
        padding: 5px 5px 5px 10px;
        font-weight: 600;
        margin-bottom: 7px;

        span {
          opacity: 0.5;
          margin-right: 7px;
          width: 16px;
        }
      }

      .bottomSection{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        padding: 10px;

        .logo {
          height: 18px;
          vertical-align: top;
        }
      }
    }
  }
}
