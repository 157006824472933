// Default values are for development mode
let API_URL = 'http://localhost:5000';
let WEBSOCKET_URL = 'ws://localhost:5001';
let APP_URL = 'http://localhost:9000';
let CORP_URL = 'http://localhost:9000';
let AUTHO_DOMAIN = 'mixtable-dev.us.auth0.com';
let AUTH0_CLIENT_ID = 'wWHafpsQcDLI5QkQDNeTe4ANPbO66Qau';
let STRIPE_STRIPE_PUBLISHABLE_KEY = 'pk_test_51IH9JeExEwWtWeUfqFushWySFzAYQEMznuzUeEASpDqxrUHjAvvZrZhnMDfxaTZzVHtJQtgaNYTrOJh11Isk3a3C00H1Mw0KOt';
let SENTRY_DSN = '';
let SHOPIFY_CLIENT_ID_BULK_EDITOR = 'd05fa9df54f8cab4d4ed505e4da67b71';
let SHOPIFY_CLIENT_ID_EXPORTER = 'd05fa9df54f8cab4d4ed505e4da67b71';
let SHOPIFY_CLIENT_ID_ANALYTICS = '19f31abf34494489582349f87e4762ca';

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://api.mixtable.com';
  WEBSOCKET_URL = 'wss://rt.mixtable.com';
  APP_URL = 'https://app.mixtable.com';
  CORP_URL = 'https://www.mixtable.com';
  AUTHO_DOMAIN = 'mixtable-production.us.auth0.com';
  AUTH0_CLIENT_ID = 'Tyq03IOZTEE3HqojvD2hFEIFfxMYSdzB';
  STRIPE_STRIPE_PUBLISHABLE_KEY = '';
  SENTRY_DSN = 'https://3da3159ea1784ce0b8c974c29cb1c7a9@o765155.ingest.sentry.io/5794234';
  SHOPIFY_CLIENT_ID_BULK_EDITOR = '749c8b1d15cf6144cd3024a4945e66b5';
  SHOPIFY_CLIENT_ID_EXPORTER = 'f70e7ab4a8b68ae7e028523f69e3a37d';
  SHOPIFY_CLIENT_ID_ANALYTICS = '19f31abf34494489582349f87e4762ca';
}

if (process.env.NODE_ENV === 'staging') {
  API_URL = 'https://poligon-api.mixtable.com';
  WEBSOCKET_URL = 'wss://poligon-rt.mixtable.com';
  APP_URL = 'https://poligon.mixtable.com';
  CORP_URL = 'https://www.mixtable.com';
  AUTHO_DOMAIN = 'mixtable-staging.us.auth0.com';
  AUTH0_CLIENT_ID = 'UQIy2AGdqkexydJEFG3KlsVzwIeR59vh';
  STRIPE_STRIPE_PUBLISHABLE_KEY = '';
  SENTRY_DSN = '';
  SHOPIFY_CLIENT_ID_BULK_EDITOR = 'NOTHING';
  SHOPIFY_CLIENT_ID_EXPORTER = 'NOTHING';
  SHOPIFY_CLIENT_ID_ANALYTICS = 'NOTHING';
}

export default {
  API_URL,
  WEBSOCKET_URL,
  APP_URL,
  CORP_URL,
  AUTHO_DOMAIN,
  AUTH0_CLIENT_ID,
  STRIPE_STRIPE_PUBLISHABLE_KEY,
  SENTRY_DSN,
  SHOPIFY_CLIENT_ID_BULK_EDITOR,
  SHOPIFY_CLIENT_ID_EXPORTER,
  SHOPIFY_CLIENT_ID_ANALYTICS,
};
