@import "../../styles/variables";

.component {
  display: inline-block;

  .cardContainer{
    width: 400px;
    height: 200px;
    padding: 20px;
    border-radius: @border-radius;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px;
    cursor: pointer;

    &:hover {
      background: @background-base;
    }


    .header {
      .icon {
        display: inline-block;
        font-size: 35px;
        vertical-align: middle;
      }

      .label {
        display: inline-block;
        padding-left: 10px;
        font-size: 20px;
        vertical-align: middle;
      }
    }

    .body {
      padding-top: 30px;
      line-height: 1.4em;
    }

    .footer {
      width: 100%;
      padding-top: 20px;

      .integrationType {
        display: inline-block;
        vertical-align: top;
        width: 48%;
        opacity: 0.5;
        line-height: 40px;
      }

      .integrationStatus {
        text-align: right;
        display: inline-block;
        width: 48%;

        .active{
          background: #B1ECB5;
          color: #1D7324;
          padding-top: 5px;
          border-radius: 20px;
          width: 30px;
          height: 30px;
          text-align: center;
          display: inline-block;
        }
        .inactive{
          background: @background-base;
          color: #5C7080;
          padding-top: 5px;
          border-radius: 20px;
          width: 30px;
          height: 30px;
          text-align: center;
          display: inline-block;
        }
      }
    }

  }
}
