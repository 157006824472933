import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthService from '../../lib/AuthService';

class PrivateRoute extends React.Component {
  state = {
    isAuthenticated: false,
    isLoading: true,
  };

  async componentDidMount() {
    const isAuthenticated = await AuthService.getInstance().isAuthenticated();
    this.setState({ isAuthenticated, isLoading: false });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isAuthenticated, isLoading } = this.state;

    return (
      <Route
        {...rest}
        render={(props) => {
          // While checking for auth
          if (isLoading) {
            return <div>
              {/* Loading ...*/}
            </div>;
          }

          // If logged-in
          if (!isAuthenticated) {
            // Redirect to the login page if not authenticated
            return <Redirect to="/" />;
          }
          // Render the component if authenticated
          return <Component {...props} />;
        }}
      />
    );
  }
}

export default PrivateRoute;

