import React, { Component } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import * as styles from './integrationsCatalogShopifyCardDetails.module.less';
import API from '../../config';
import Logger from '../../models/logger';
import AxiosClient from '../../lib/AxiosClient';

/**
 * An empty component to speed up new component creation
 */
class IntegrationsCatalogShopifyCardDetails extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {
      showError: props.showError,
      showToast: props.showToast,
    };

    /* Create our logger */
    this.logger = new Logger('IntegrationsCatalogShopifyCardDetails');

    this.state = {
      workspace: props.workspace,
      integrationConfigs: props.integrationConfigs,
    };
  }

  componentDidMount() {

  }


  /**
   * Triggers a reload of store data from Shopify to Mixtable
   * @param store
   */
  async reloadShopifyStoreData(store, dataType) {
    try {
      await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${store}/reloadStoreData/${dataType}`);

      /* Show a toast to the user */
      this.parentFunctions.showToast({
        message: `Queued reload of ${dataType} data for ${store}`,
        icon: 'saved',
        intent: Intent.SUCCESS,
      });

      /* Now disable the reload button until the drawer is closed, to prevent accidental double ups */
      this.setState({ [`disabledStoreReload_${dataType}_${store}`]: true });
    } catch (err) {
      this.logger.error('reloadShopifyStoreData', `Error reloading shopify store data: ${err}`);

      this.parentFunctions.showError(err);
    }
  }


  render() {
    return (
      <div className={styles.component}>

        <div className={styles.introduction}>
          To connect Mixtable to Shopify please install the <a
            href={'https://apps.shopify.com/mixtable?utm_source=app&utm_medium=referral&utm_campaign=integration_config'}
            target={'_blank'} rel="noreferrer">Mixtable Connector app</a> from the Shopify app
          store. After you've installed it, the Connector app will prompt you for a "Workspace ID". Enter the value
          shown below. This will give Mixtable read and write access to your store's data. When done just come back
          here.
        </div>
        <div className={styles.warning}>
          When you add a new store to Mixtable we will immediately start syncing your data so that we can make the
          spreadsheet interface snappy, and any further syncs super fast. This initial sync may take some time,
          depending on how much data you have. Our systems will work feverishly to get this completed quickly,
          but you may have to wait a bit before you can see all your data in a workbook.
        </div>
        <div className={styles.workspaceInformation}>
          Workspace ID: <strong>{this.state.workspace.id}</strong>
        </div>
        {this.state.integrationConfigs && this.state.integrationConfigs.length > 0 &&
        <div className={styles.connectedStores}>
          {this.state.integrationConfigs.map(config =>
            <div className={styles.store} key={config.id}>
              <div className={styles.name}>{config.target_id}</div>
              <div className={styles.buttons}>
                <div>
                  <Button disabled={this.state[`disabledStoreReload_products_${config.target_id}`]} small={false}
                    text={'Reload product, collections, and inventory Shopify data'} onClick={() => {
                      this.reloadShopifyStoreData(config.target_id, 'products');
                    }}/></div>
                <div><Button disabled={this.state[`disabledStoreReload_orders_${config.target_id}`]} small={false}
                  text={'Reload orders Shopify data'} onClick={() => {
                    this.reloadShopifyStoreData(config.target_id, 'orders');
                  }}/></div>
                <div><Button disabled={this.state[`disabledStoreReload_customers_${config.target_id}`]} small={false}
                  text={'Reload customers Shopify data'} onClick={() => {
                    this.reloadShopifyStoreData(config.target_id, 'customers');
                  }}/></div>
              </div>

            </div>)}
        </div>}


      </div>
    );
  }
}

export default IntegrationsCatalogShopifyCardDetails;
