import React, { Component } from 'react';
import {Button, Intent} from '@blueprintjs/core';
import * as styles from './connectedStore.module.less';
import {INTENT_PRIMARY} from "@blueprintjs/core/lib/esnext/common/classes";

/**
 * The Connected Stores Component within the Home Page
 */
class ConnectedStores extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openIntegrationsCatalogScreen: props.openIntegrationsCatalogScreen,
    };

    this.state = {
      storesSubscriptions: props.storesSubscriptions,
    };
  }

  render() {
    return (
      <div className={styles.component}>
        <div className={styles.connectedStoresContainer}>
          <div className={styles.header}>
            <h2>Connected Stores</h2>
          </div>

          {/* Case: User has connected stores to Mixtable */}
          {this.state.storesSubscriptions && Object.keys(this.state.storesSubscriptions).length > 0 && <div className={styles.stores}>
            {Object.keys(this.state.storesSubscriptions).map(store => (
              <div key={store} className={styles.store}>
                <div className={styles.storeName}>
                  <span className={'icon-shopify'} style={{ marginRight: '10px', opacity: '0.9' }} />
                  <strong>{store}</strong>
                </div>
                <div className={styles.plan}>
                  {this.state.storesSubscriptions[store].replace('partner_test', 'Free Plan')}
                </div>
              </div>
            ))}
          </div>}

          <Button
            className={styles.connectShopifyStoreButton}
            // intent={Intent.PRIMARY}
            text={'Connect Shopify Store'}
            icon={'add'}
            fill={true}
            // minimal={true}
            // outlined={true}
            onClick={this.parentFunctions.openIntegrationsCatalogScreen}
          />
        </div>
      </div>
    );
  }
}

export default ConnectedStores;
