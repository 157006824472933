import React, { Component } from 'react';
import * as styles from './integrationsCatalog.module.less';
import IntegrationsCatalogCard from '../integrationsCatalogCard';
import API from '../../config';
import Logger from '../../models/logger';
import AxiosClient from '../../lib/AxiosClient';

/**
 * Component shows a list of our possible integrations
 */
class IntegrationsCatalog extends Component {
  constructor(props) {
    super(props);

    this.integrationOptions = [
      'shopify',
    ];

    this.parentFunctions = {
      showError: props.showError,
      showToast: props.showToast,
    };

    /* Create our logger */
    this.logger = new Logger('IntegrationsCatalog');

    this.state = {
      workspace: props.workspace,
      activeIntegrationOptions: [],
      inactiveIntegrationOptions: [],
    };
  }

  async componentDidMount() {
    try {
      /* Get the activated integration configs from the back-end */
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrationConfigs/${this.state.workspace.id}/`);

      const integrationConfigs = response.data;

      const activeConfigs = [];
      const inactiveConfigs = [];

      const newIntegrationConfigState = {
        shopify: [],
      };

      integrationConfigs.forEach((config) => {
        if (config.target === 'shopify') {
          newIntegrationConfigState[config.target].push(config);
        }
      });

      this.integrationOptions.forEach((option) => {
        const active = newIntegrationConfigState[option] && newIntegrationConfigState[option].length > 0;

        if (active) {
          activeConfigs.push(option);
        } else {
          inactiveConfigs.push(option);
        }
      });

      this.setState({
        inactiveIntegrationOptions: inactiveConfigs,
        activeIntegrationOptions: activeConfigs,
        integrationConfigs: newIntegrationConfigState,
      });
    } catch (err) {
      this.logger.error('componentDidMount', `Error loading integration configs: ${err}`);

      this.parentFunctions.showError(err);
    }
  }

  render() {
    return (
      <div className={styles.component}>
        {this.state.activeIntegrationOptions.map(option =>
          <IntegrationsCatalogCard showToast={this.parentFunctions.showToast} showError={this.parentFunctions.showError} workspace={this.state.workspace} integrationConfigs={this.state.integrationConfigs[option]} active={true} integrationOption={option} key={option}/>)}
        {this.state.inactiveIntegrationOptions.map(option =>
          <IntegrationsCatalogCard showToast={this.parentFunctions.showToast} showError={this.parentFunctions.showError} workspace={this.state.workspace} active={false} integrationOption={option} key={option}/>)}
      </div>
    );
  }
}

export default IntegrationsCatalog;
