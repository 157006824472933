@import "../../../../styles/variables";

.component {
  margin-bottom: 50px;

  .createWorkbookCards {
    display: flex;
    gap: 10px;
    flex-direction: row;

    .createBlankWorkbook,
    .createShopifyStarterWorkbook {
      flex: 1 1 0px;
      border-radius: @border-radius;
      border: 1px solid #eeeef2;
      padding: 10px 0 0px 0;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 30px rgba(48,48,54,.04);
      }

      .cardHeader {
        font-size: 20px;
        font-weight: 600;
        margin: 10px;
        color: @foreground-primary;
      }

      .cardBody {
        font-size: 13px;
        line-height: 1.5em;
        color: #797986;
        padding: 10px 0 0 51px;

        .screenshot {
          text-align: right;
          margin-top: 20px;
          border-top: 1px solid #eeeef2;
          border-left: 1px solid #eeeef2;
          border-top-left-radius: @border-radius * 2;
          overflow: hidden;
          img {
            width: 100%;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
