import React from 'react';

class Error404 extends React.Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (<div style={{ padding: '20px' }}>
      There is nothing here. If something is supposed to be, well, we have a bug. Please email team@mixtable.com
    </div>);
  }
}

export default Error404;
