/**
 * The Logger to be used by the rest of the app
 * @param source
 * @constructor
 */
function Logger(source) {
  this.source = source || 'Unknown';
}

Logger.prototype.verbose = function (functionName, message, data) {
  // console.log(`${this.source} => ${functionName} => ${message} ${data ? JSON.stringify(data) : '[no data]'}`);
};

Logger.prototype.info = function (functionName, message, data) {
  // console.log(`%c${this.source} => ${functionName} => ${message} ${data ? JSON.stringify(data) : '[no data]'}`, 'color: #42C0FB');
};

Logger.prototype.error = function (functionName, message, data) {
  // console.log(`%c${this.source} => ${functionName} => ${message} ${data ? JSON.stringify(data) : '[no data]'}`, 'color: #EE2C2C');
};

export default Logger;
