@import "../styles/variables";

.component {

  .loadingAnimationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
  }

  .contentContainer {
    padding: 20px 25px 25px 265px;

    &.noSidebar {
      padding-left: 25px;
    }

    .sectionHeading {
      margin: 0;
      font-weight: 600;

      font-size: 16px;
      line-height: 32px;

      padding-bottom: 10px;
    }
  }
}
