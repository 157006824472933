@import "../../../../styles/variables";

.component {
  margin-bottom: 50px;

  .helpArticlesCatalog {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 10px;
    cursor: pointer;
    align-items: stretch;

    .helpArticlesCard {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      width: 100%;
      height: 100%;
      border: 1px solid @white;
      //border-radius: @border-radius;
      //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px 10px;
      //background-color: #fff;

      .helpArticlesCardHeader {
        display: flex;
        height: fit-content;
        margin-bottom: 7px;
        padding-right: 20px;
        color: @foreground-primary;

        span {
          margin: 1px 10px 0 1px;
          font-size: 16px;
          color: @foreground-primary;
          opacity: 0.7;
        }

        h3 {
          margin: 0;
          overflow-wrap: anywhere;
        }
      }

      .helpArticlesCardBody {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 28px;
        color: #797986;
        font-size: 12px;
      }

      &:hover {
        box-shadow: 0 4px 30px rgba(48,48,54,.04);
        border: 1px solid #eeeef2;
      }
    }
  }
}
