@import "../../../../styles/variables";

.component {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 750px;

  .left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 10px);
    height: 100%;

    border-radius: @border-radius * 2;
    border: 1px solid @border-color-1;
  }

  .thumbnail {
    vertical-align: top;
    width: 100%;

    border-top-left-radius: @border-radius * 2;
    border-top-right-radius: @border-radius * 2;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .content {
    padding: 20px;
    background: @white;

    border-bottom-left-radius: @border-radius * 2;
    border-bottom-right-radius: @border-radius * 2;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 20px;
  }

  .description {
    margin-bottom: 15px;
    line-height: 1.5em;
    height: 150px;
  }

  .selectStore {
    height: 60px;
    //margin-bottom: 15px;
  }

  .callout {
    margin-bottom: 30px;
  }

  .emptyPlaceholder {
    height: 60px;
    //margin-bottom: 15px;
  }

  .createWorkbookButton {
    margin: auto;
  }
}
