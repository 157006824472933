import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import * as styles from './checkout.module.less';
import AppConfig from '../../config';
import AxiosClient from '../../lib/AxiosClient';


class ShopifyCheckoutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      working: false,
      workspaceId: props.match.params.workspaceId,
      storeId: props.match.params.storeId,
    };
  }

  /**
   * Starts process to create a Shopify subscription
   * @param planId
   * @returns {Promise<void>}
   */
  async createShopifySubscription(planId) {
    // console.log(`createShopifySubscription = ${planId}`);

    this.setState({ working: true }, async () => {
      /* Get the session data, and send the user to Stripe */
      try {
        const response = await AxiosClient.getInstance().post(`${AppConfig.API_URL}/1.0/billing/shopify/create-recurring-charge/`, {
          planId: planId,
          workspaceId: this.state.workspaceId,
          store: this.state.storeId,
        });

        const confirmationUrl = response.data.confirmationUrl;

        window.location = confirmationUrl;
      } catch (err) {
        throw new Error(`Exception in billing: ${err}`);
      }
    });
  }


  render() {
    return (<div className={styles.component}>


      <div className={styles.pricingPlansContainer}>

        <h2>Upgrade your plan - <span>{this.state.storeId}</span></h2>

        <div className={styles.content}>
          <div>
            <div className={styles.column}>

            </div>

            <div className={styles.column}>
              <div className={styles.planName}>Basic</div>
              <div className={styles.planPrice}>
                <div className={styles.value}>$9</div>
                <div className={styles.qualifier}>per month</div>
              </div>
              <div className={styles.planCta}>
                <Button intent={'primary'} minimal={false} outlined={false} fill={true} loading={this.state.working} onClick={() => {
                  this.createShopifySubscription('basic-plan');
                }}>Upgrade</Button>
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.planName}>Plus</div>
              <div className={styles.planPrice}>
                <div className={styles.value}>$29</div>
                <div className={styles.qualifier}>per month</div>
              </div>
              <div className={styles.planCta}>
                <Button intent={'primary'} minimal={false} outlined={false} fill={true} loading={this.state.working} onClick={() => {
                  this.createShopifySubscription('plus-plan');
                }}>Upgrade</Button>
              </div>
            </div>

          </div>

          {/* Features row*/}
          <div>

            {/* List of features*/}
            <div className={styles.column}>
              <div className={styles.headingCell}>Features</div>
              <div className={styles.cell}>Shopify to Mixtable sync</div>
              <div className={styles.cell}>Mixtable to Shopify sync
                <div className={styles.featureBreakdown}>Core data</div>
                <div className={styles.featureBreakdown}>Metafields (custom fields)</div>
              </div>
            </div>

            {/* Basic plan features*/}
            <div className={styles.column}>
              <div className={styles.headingCell}>&nbsp;</div>

              <div className={styles.cell}>
                <div className={styles.checkmark}>
                  <span className={'icon-checkmark'}/>
                </div>
              </div>

              <div className={styles.cell}>
                <div className={styles.checkmark}>
                  <div className={styles.featureBreakdown}>&nbsp;</div>
                  <div className={styles.featureBreakdown}>&nbsp;</div>
                  <div className={styles.featureBreakdown}>&nbsp;</div>
                </div>
              </div>
            </div>

            {/* Plus plan features*/}
            <div className={styles.column}>
              <div className={styles.headingCell}>&nbsp;</div>
              <div className={styles.cell}>
                <div className={styles.checkmark}>
                  <span className={'icon-checkmark'}/>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.checkmark}>
                  <div className={styles.featureBreakdown}>&nbsp;</div>
                  <div className={'icon-checkmark'}/>
                  <div className={'icon-checkmark'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className={styles.footer}>*/}
        {/*  Development stores, trial, and NPO stores are automatically enrolled in our free plan.*/}
        {/*</div>*/}
      </div>
    </div>);
  }
}

export default ShopifyCheckoutPage;
