import React, { Component } from 'react';
import { Classes, Drawer, Icon, Position } from '@blueprintjs/core';
import * as styles from './integrationsCatalogCard.module.less';
import IntegrationOptions from './integrationOptions.json';
import IntegrationsCatalogShopifyCardDetails from '../integrationsCatalogShopifyCardDetails';
import Logger from '../../models/logger';

/**
 * Represents a card allowing the user to integrate with a specific system
 */
class IntegrationsCatalogCard extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {
      showError: props.showError,
      showToast: props.showToast,
    };

    /* Create our logger */
    this.logger = new Logger('IntegrationsCatalogCard');


    this.state = {
      integrationActive: props.active,
      integrationOption: props.integrationOption,
      workspace: props.workspace,
      integrationData: IntegrationOptions[props.integrationOption],
      integrationConfigs: props.integrationConfigs,
      drawerOpen: false,
      disabler: false,
    };
  }

  /**
   * Opens the configuration drawer
   */
  openDrawer = () => {
    if (!this.state.disabler) {
      this.setState({
        drawerOpen: true,
      });
    }
  }

  /**
   * Closes the configuration drawer
   */
  closeDrawer = () => {
    this.setState({
      drawerOpen: false,
      disabler: true,
    }, () => {
      setTimeout(() => {
        this.setState({ disabler: false });
      }, 100);
    });
  }


  render() {
    return (
      <div className={styles.component}>

        <div className={styles.cardContainer} onClick={this.openDrawer}>
          <div className={styles.header}>
            <div className={styles.icon}>
              <span className={this.state.integrationData.iconClass}/>
              <div className={styles.label}>{this.state.integrationData.label}</div>
            </div>
          </div>
          <div className={styles.body}>
            {this.state.integrationData.description}
          </div>
          <div className={styles.footer}>
            <div className={styles.integrationType}>
              {this.state.integrationData.type}
            </div>
            <div className={styles.integrationStatus}>
              {this.state.integrationActive && <div className={styles.active}>
                <Icon icon={'tick'} size={20}/>
              </div>}
              {!this.state.integrationActive && <div className={styles.inactive}>
                <Icon icon={'plus'} size={20}/>
              </div>}
            </div>
          </div>
        </div>

        {/* The drawer we open when the card is clicked is below*/}
        {this.state.integrationOption && <Drawer
          icon="cloud"
          usePortal={false}
          position={Position.RIGHT}
          onClose={this.closeDrawer}
          title={this.state.integrationData.label}
          isOpen={this.state.drawerOpen}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
              {/* Shopify*/}
              {this.state.integrationOption === 'shopify' && <IntegrationsCatalogShopifyCardDetails showToast={this.parentFunctions.showToast} showError={this.parentFunctions.showError} workspace={this.state.workspace} integrationConfigs={this.state.integrationConfigs}/>}
            </div>
          </div>
        </Drawer>}


      </div>
    );
  }
}

export default IntegrationsCatalogCard;
