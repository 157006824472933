import React from 'react';
import Config from '../../config';
import * as styles from '../home.module.less';
import AuthService from '../../lib/AuthService';

/**
 * Oauth login callback
 */
class LoginCallback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      API_URL: Config.API_URL,
    };
  }

  componentDidMount() {
    const loginHash = window.location.hash;

    /* Now, remove all query parameters from the URL */
    this.props.history.replace('/oauth/callback');

    const handleAuthentication = (nextState, replace) => {
      // auth.handleAuthentication(loginHash);

      AuthService.getInstance().handleAuthenticationCallback(loginHash);
    };

    handleAuthentication();
  }

  render() {
    return (
      <div className={styles.component}>
        {/* Mixtable LoginCallback*/}
      </div>
    );
  }
}


export default LoginCallback;
