import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import appLogo from '../../../assets/images/logo/brandmark-green-200.png';
import * as styles from './sidebar.module.less';

/**
 * The Sidebar Component within the Home Page
 */
class Sidebar extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openWorkbooksScreen: props.openWorkbooksScreen,
      openIntegrationsCatalogScreen: props.openIntegrationsCatalogScreen,
    };

    this.state = {
      currentWorkspace: props.currentWorkspace,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles.component}>
        <div className={styles.sidebar}>
          {this.state.currentWorkspace && <div>
            <div className={styles.orgName}>
              <a href={'/account'}>{this.state.currentWorkspace.name}</a>
            </div>
          </div>}
          <div className={styles.sidebarMenu}>
            <div className={styles.menuItem} onClick={this.parentFunctions.openWorkbooksScreen}>
              <span className={'icon-workbook'}/>
              Workbooks
            </div>
            <div className={styles.menuItem} onClick={this.parentFunctions.openIntegrationsCatalogScreen}>
              <Icon icon={'cloud'} size={14}/>
              Integrations
            </div>
            <div className={styles.bottomSection}>
              <img alt={'Mixtable'} src={appLogo} className={styles.logo}/>
              <Link className={styles.menuItem} to={'/logout'}>Logout</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
