import auth0 from 'auth0-js';
import axios from 'axios';
import shopifyAppBridgeApp from '@shopify/app-bridge';
import queryString from 'query-string';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import AppConfig from '../config';

class AuthService {
  static instance = null;

  // The constructor now accepts parameters
  constructor() {
    // console.log('AuthService constructor ...');
    // console.log(`AuthService constructor ... ${this.shopifyHost}`);

    if (!AuthService.instance) {
      // console.log('AuthService - Need to create instance');

      // console.log(`AuthService window.location.href ==>>  ${window.location.href}`);
      // console.log(`AuthService window.location ==>>  ${window.location}`);
      // console.log(`AuthService window.location ==>>  ${JSON.stringify(window.location)}`);

      const queryParams = queryString.parse(location.search);
      // console.log(`AuthService queryParams: ${JSON.stringify(queryParams)}`);

      // if (hostname === 'localhost' || subdomain === 'embedded' || queryParams.embedded) {
      if (queryParams.embedded) {
        // Shopify is auth provider
        this.provider = 'shopify';

        // If we haven't set the store name yet (host param) then do so now
        if (!this.shopifyHost) this.shopifyHost = queryParams.host;

        // console.log(`shopifyHost: ${this.shopifyHost}`);

        // Set up API keys
        const apiKeys = {
          bulk_editor: AppConfig.SHOPIFY_CLIENT_ID_BULK_EDITOR,
          analytics: AppConfig.SHOPIFY_CLIENT_ID_ANALYTICS,
          exporter: AppConfig.SHOPIFY_CLIENT_ID_EXPORTER,
        };

        // Create the Config JSON
        const config = {
          apiKey: apiKeys[queryParams.appId],
          host: this.shopifyHost,
          forceRedirect: true,
        };
        // console.log(`shopify config ==> ${JSON.stringify(config)}`);
        this.appBridgeApp = shopifyAppBridgeApp(config);
      } else {
        // All other cases assume auth0
        this.provider = 'auth0';
        this.auth0 = new auth0.WebAuth({
          domain: AppConfig.AUTHO_DOMAIN,
          clientID: AppConfig.AUTH0_CLIENT_ID,
          redirectUri: `${AppConfig.APP_URL}/oauth/callback`,
          responseType: 'token id_token',
          scope: 'openid',
        });

        // console.log('AuthService constructed for auth0');
      }


      AuthService.instance = this;
    }
    return AuthService.instance;
  }


  /**
   * Returns the singleton instance
   * @returns {null}
   */
  static getInstance() {
    // console.log('AuthService.getInstance');

    if (!AuthService.instance) {
      // console.log('AuthService.getInstance - creating ...');
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  /**
   * Sets an autho session
   * @param authResult
   */
  setAuth0Session = function (authResult) {
    // Set the time that the Access Token will expire at
    if (typeof localStorage !== 'undefined') {
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);
    }
  }

  /**
   * Refreshes an auth0 session.
   * @returns {Promise<unknown>}
   */
  refreshAuth0Session() {
    // console.log('refreshAuth0Session called');

    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, async (err, authResult) => {
        // console.log(`refreshAuth0Session checkSession authResult => ${JSON.stringify(authResult)}`);

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setAuth0Session(authResult);

          // Update the session token on the server
          try {
            await axios.post(`${AppConfig.API_URL}/1.0/user/login`, {
              token: authResult.accessToken,
            });

            resolve(authResult);
          } catch (axiosError) {
            console.log(`Auth user login error in checkSession: ${axiosError}`);
          }
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  /**
   * Returns the session of the currently logged-in Auth0 user
   * @returns {Promise<{expires_at: string, id_token: string, token: string}|null>}
   */
  async getAuth0Session() {
    // console.log('getAuth0Session');

    if (typeof localStorage !== 'undefined') {
      /* If the session exists, and is about to expire, refresh it */
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      // console.log(`expiresAt: ${expiresAt}`);

      // console.log(`expires math: ${expiresAt - new Date().getTime()}  ==>>  ${(expiresAt - new Date().getTime()) / 1000 / 60} min`);
      if ((expiresAt - new Date().getTime()) <= 5 * 60 * 1000) {
        // console.log('expiresAt about to expire');

        try {
          await this.refreshAuth0Session();
        } catch (err) {
          console.log(`error from refreshAuth0Session  ==>>  ${err}`);
          return null; // On an error at refresh, return a null session
        }

        // console.log('after checkSession');
      } else {
        // console.log('expiresAt is good');
      }


      const session = {
        token: localStorage.getItem('token'),
        id_token: localStorage.getItem('id_token'),
        expires_at: localStorage.getItem('expires_at'),
      };

      // console.log(`getSession returning ${JSON.stringify(session)}`);

      return session;
    }

    // console.log('returning null because no localstorage');
    return null;
  }

  /**
   * Returns true if the current user is currently authenticated
   * @returns {Promise<boolean>}
   */
  async isAuthenticated() {
    // If Shopify, test if we can get a token, then return true if so
    if (this.provider === 'shopify') {
      const token = await getSessionToken(this.appBridgeApp);
      return !!token;
    }
    // auth0
    const session = await this.getAuth0Session();

    // console.log(`isAuthenticated returning ${!!session}`);

    return !!session;
  }

  /**
   * Returns an oauth token based on whichever auth system we are using
   * @returns {Promise<string>}
   */
  async getToken() {
    // console.log('AuthService getToken called');

    let token = null;

    if (this.provider === 'auth0') {
      // Auth0

      // console.log('AuthService getToken - auth0 provider');
      const session = await this.getAuth0Session();
      token = session.token;
    } else if (this.provider === 'shopify') {
      // Shopify

      // console.log('AuthService getToken - shopify provider');
      token = await getSessionToken(this.appBridgeApp);
      token = token.replace('Bearer ', ''); // Remove the Bearer bit
      token = `shopify ${token}`; // Prepend the token with "shopify "
    }

    // console.log(`AuthService.getToken returning  ==>>  ${this.provider}  ==>>  ${token}`);
    return token;
  }

  /**
   * Triggers Auth0 login
   */
  login() {
    this.auth0.authorize({});
  }

  /** Trigger Auth0 signup */
  signup() {
    this.auth0.authorize({
      signup: true,
    });
  }

  /**
   * Triggers a logout
   */
  logout() {
    // console.log('logout called');
    // Clear Access Token and ID Token from local storage
    if (typeof localStorage !== 'undefined') {
      try {
        axios.post(`${AppConfig.API_URL}/1.0/user/logout`, {}, {
          headers: {
            Token: localStorage.getItem('token'),
          },
        });

        localStorage.removeItem('token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');

        window.location = `https://${AppConfig.AUTHO_DOMAIN}/v2/logout?returnTo=${encodeURIComponent(AppConfig.CORP_URL)}`;
      } catch (err) {
        console.log(`Auth logout error: ${err}`);
      }
    }
  }


  /**
   * Function authenticates a user to our API with shopify credentials
   * @returns {Promise<void>}
   */
  async handleShopifyAuthentication(appId) {
    // console.log('handleShopifyAuthentication');

    /* IMPORTANT - Store the appId in memory as we'll need to add it to every authenticated call */
    this.appId = appId;

    try {
      await axios.post(`${AppConfig.API_URL}/1.0/user/shopifyLogin/`, {}, {
        headers: {
          Token: await this.getToken(),
          appid: this.appId,
        },
      });
    } catch (err) {
      console.log(`Auth error logging user in (s): ${err}`);
    }
  }


  /**
   * Handles the login callback from Auth0
   * @param hash
   */
  handleAuthenticationCallback(hash) {
    // console.log('in handleAuthenticationCallback');

    this.auth0.parseHash({ hash: hash }, async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setAuth0Session(authResult);

        // Now get the session
        const sessionObject = await this.getAuth0Session();

        try {
          await axios.post(`${AppConfig.API_URL}/1.0/user/login`, {
            token: sessionObject.token,
          }, {
            headers: {},
          });

          window.location = '/home'; // If we get to here, redirect the user to go home. Use window.location hard redirect to force app reload
        } catch (err) {
          console.log(`Auth error logging user in: ${err}`);
        }
      } else if (err) {
        console.log(`Auth error parsing hash: ${err}`);

        window.location = '/'; // If we get to here, something went wrong. Go to root. Use window.location hard redirect to force app reload
      }
    });
  }
}

export default AuthService;
