import React, { Component } from 'react';
import * as styles from './helpArticles.module.less';
import helpArticles from './helpArticles.json';

/**
 * The HelpArticles Component within the Home Page
 */
class HelpArticles extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {};

    this.state = {
      helpArticles: helpArticles,
    };
  }

  render() {
    return (
      <div className={styles.component}>
        <div className={styles.helpArticlesCatalog}>
          {this.state.helpArticles.map(helpArticle => (
            <a key={helpArticle.url} href={`${helpArticle.url}?utm_source=app&utm_medium=referral&utm_campaign=dashboard`} target={'_blank'}>
              <div className={styles.helpArticlesCard}>
                <div className={styles.helpArticlesCardHeader}>
                  <span className={'icon-help-thin'}/>
                  <h3>{helpArticle.title}</h3>
                </div>
                <div className={styles.helpArticlesCardBody}>
                  {helpArticle.description}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default HelpArticles;
