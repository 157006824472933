import React, { Component } from 'react';
import * as styles from './loadingAnimationPage.module.less';
import LoadingAnimation from '../loadingAnimation';

/**
 * Component displays a full screen loading animation page
 */
class LoadingAnimationPage extends Component {
  render() {
    return (
      <div className={styles.component}>
        <LoadingAnimation/>
      </div>
    );
  }
}

export default LoadingAnimationPage;
