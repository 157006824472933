@import "../../styles/variables";

.component {
  padding: 10px;

  h3 {
    padding-bottom: 0;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .events {
    padding: 10px 0 0 0;
    width: 100%;

    .event {
      padding-bottom: 5px;

      .dateTime {
        display: inline-block;
        vertical-align: top;
        width: 20%;
      }

      .name {
        display: inline-block;
        vertical-align: top;
        width: 20%;
      }

      .target {
        display: inline-block;
        vertical-align: top;
        width: 20%;
      }

      .details {
        display: inline-block;
        vertical-align: top;
        width: 20%;
      }
    }
  }
}
