@import "../../styles/variables";

.component {
  padding: 10px;

  h3 {
    padding-bottom: 0;
    margin-bottom: 5px;
    font-weight: 600;
  }


  .workspaceWorkbooks{
    padding-left: 20px;
    padding-bottom: 10px;

    .workbookList {
      padding-bottom: 20px;
      padding-left: 5px;

      .workbookEntry {
        display: block;
        padding: 4px 0 4px 0;
      }

      .addWorkbookButton {
        margin-top: 15px;
      }
    }
  }


  .integrations {
    padding-top: 10px;

    .workspaceName {
      padding-bottom: 5px;
    }

    .workspaceIntegrationConfigs {
      padding-left: 20px;

      .integrationConfig {
        margin-bottom: 20px;

        .targetId {
          margin-bottom: 10px;
          font-weight: 600;
        }

        .dataLoadingControls {
          display: inline-block;
          vertical-align: top;
          width: 30%;

          .button {
            margin-bottom: 5px;
          }
        }

        .subscriptionControls {
          display: inline-block;
          vertical-align: top;
          width: 30%;

          .button {
            margin-bottom: 5px;
          }
        }

        .adminControls {
          display: inline-block;
          vertical-align: top;
          width: 30%;

          .button {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
