import React from 'react';
import * as styles from './maintenance.module.less';

/**
 * Maintenance page component - shows "under maintenance" message to user
 */
class MaintenancePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
  }


  render() {
    return (<div className={styles.component}>
      <h3>Mixtable will be back shortly</h3>
      We are currently deploying new code, and we've put Mixtable into maintenance mode until we are done. We should wrap things up shortly.
    </div>);
  }
}

export default MaintenancePage;
