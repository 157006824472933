import React from 'react';
import * as styles from './startPage.module.less';
import AuthService from '../lib/AuthService';

/**
 * Start page component - either sends the user to the /home page, or gets her to login
 */
class StartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const isAuthenticated = await AuthService.getInstance().isAuthenticated();
    // console.log(`startpage isAuthenticated => ${isAuthenticated}`);
    if (isAuthenticated) {
      /* If logged in, send to home page. Use window.location hard redirect to force app reload on dashboard */
      window.location = '/home';
    } else {
      /* If not logged in, send to login page. Use window.location hard redirect to force app reload on login page */
      window.location = '/login';
    }
  }


  render() {
    return (<div className={styles.component}>
      {/* Welcome to Mixtable*/}
    </div>);
  }
}

export default StartPage;
