.component {
  display: flex;
  align-items: center;
  justify-content: center;

  .loader1 {
    animation-name: rotate;
    animation-duration: 2.0s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    img {
      height: 50px;
    }
  }

  @keyframes rotate {
    0% {
      transform:rotate(0);
    }
    100% {
      transform:rotate(360deg);
    }
  }

  @keyframes jelly
  {
    0% {
      animation-timing-function: cubic-bezier(0.1441,0.1912,0.6583,1.1029);
      transform: translate(0px,0) skewX(0deg);
    }
    31% {
      animation-timing-function: cubic-bezier(0.0667,0.1419,0.6667,1.1415);
      transform: translate(-7.800000000000001px,0) skewX(7.800000000000001deg);
    }
    45% {
      animation-timing-function: cubic-bezier(0.0542,0.1151,0.5697,1.181);
      transform: translate(4.680000000000001px,0) skewX(-4.680000000000001deg);
    }
    59% {
      animation-timing-function: cubic-bezier(0.0497,0.1058,0.4541,1.231);
      transform: translate(-2.8100000000000005px,0) skewX(2.8100000000000005deg);
    }
    73% {
      animation-timing-function: cubic-bezier(0.0808,0.1711,0.4109,1.2519);
      transform: translate(1.6800000000000002px,0) skewX(-1.6800000000000002deg);
    }
    87% {
      animation-timing-function: cubic-bezier(0.2073,0.3705,0.4064,0.8839);
      transform: translate(-1.01px,0) skewX(1.01deg);
    }
    100% {
      transform: translate(0.78px,0) skewX(-0.78deg);
    }
  }

  @keyframes slide-ltr
  {
    0% {
      animation-timing-function: cubic-bezier(0.4652,0.1051,0.774,0.6426);
      transform: translate(0px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 1;
    }
    22.5% {
      animation-timing-function: cubic-bezier(0.4142,0.3131,0.7623,0.6513);
      transform: translate(47.8px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 1;
    }
    45% {
      animation-timing-function: cubic-bezier(0.3615,0.331,0.9646,1.3461);
      transform: translate(147.8px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 1;
    }
    47.5% {
      animation-timing-function: cubic-bezier(0.7006,0.3332,0.6667,0.6667);
      transform: translate(165.6px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 0;
    }
    50% {
      animation-timing-function: cubic-bezier(0.1604,0.3176,-0.0218,0.6965);
      transform: translate(-200px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 0;
    }
    51% {
      animation-timing-function: cubic-bezier(0.1983,0.3529,0.5263,0.6945);
      transform: translate(-179.8px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 0;
    }
    80.4% {
      animation-timing-function: cubic-bezier(0.2342,0.3708,0.5422,0.9065);
      transform: translate(-38.4px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 1;
    }
    100% {
      transform: translate(0px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
      opacity: 1;
    }
  }
}
