import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import shopifyAppBridgeApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';
import API from '../../config';


/**
 * Start page component - either sends the user to the /home page, or gets her to login
 */
class ShopifyLogin extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(location.search);
    // console.log(`ShopifyLogin queryParams: ${JSON.stringify(queryParams)}`);

    this.state = {
      shop: queryParams.shop,
      host: queryParams.host,
      embedded: queryParams.embedded,
      queryParams,
      locationSearch: location.search,
    };

    /* Determine which app we are using */
    this.APPID = 'BULK_EDITOR'; // default app id is bulk editor
    if (window.location.pathname.indexOf('/shopifyLoginExporter') > -1) {
      // We are in Exporter
      this.APPID = 'EXPORTER';
    } else if (window.location.pathname.indexOf('/shopifyLoginAnalytics') > -1) {
      // We are in Analytics
      this.APPID = 'ANALYTICS';
    }
    // console.log(`APPID ShopifyLogin: ${this.APPID}`);

    /* Get our API key. Default is bulk editor. */
    let apiKey = API.SHOPIFY_CLIENT_ID_BULK_EDITOR;
    if (this.APPID === 'EXPORTER') {
      apiKey = API.SHOPIFY_CLIENT_ID_EXPORTER;
    } else if (this.APPID === 'ANALYTICS') {
      apiKey = API.SHOPIFY_CLIENT_ID_ANALYTICS;
    }
    // console.log(`API KEY ShopifyLogin: ${apiKey}`);

    /* Configure App Bridge**/
    const config = {
      apiKey,
      host: queryParams.host,
      forceRedirect: true,
    };
    // console.log(`launch config ==> ${this.APPID} ==> ${JSON.stringify(config)}`);
    this.appBridgeApp = shopifyAppBridgeApp(config);
    this.redirect = Redirect.create(this.appBridgeApp);

    // console.log('ShopifyLogin constructor done');
  }

  async componentDidMount() {
    // console.log('login componentDidMount - START');

    // Build oauth login URL. By default - bulk editor
    let oauthLoginUrl = `${API.API_URL}/1.0/integrations/shopify/connector/bulk_editor/oauth/login/${encodeURIComponent(this.state.locationSearch.toString())}`;
    if (this.APPID === 'EXPORTER') {
      oauthLoginUrl = `${API.API_URL}/1.0/integrations/shopify/connector/exporter/oauth/login/${encodeURIComponent(this.state.locationSearch.toString())}`;
    } else if (this.APPID === 'ANALYTICS') {
      oauthLoginUrl = `${API.API_URL}/1.0/integrations/shopify/connector/analytics/oauth/login/${encodeURIComponent(this.state.locationSearch.toString())}`;
    }
    // console.log(`oauthLoginUrl ==>> ${oauthLoginUrl}`);

    if (this.state.embedded) {
    // Get the initial session token
      const sessionToken = await getSessionToken(this.appBridgeApp);
      // console.log(`Embedded - session token: ${sessionToken}`);

      /* Start the login process */
      try {
      // Get the installed status for the store
        const response = await axios.get(`${API.API_URL}/1.0/integrations/shopify/connector/isInstalled/${this.APPID.toLowerCase()}`, {
          headers: {
            Token: `${sessionToken}`,
            appid: this.APPID.toLowerCase(),
          },
        });
        // console.log(`isInstalled for ${this.state.shop}: ${JSON.stringify(response.data)}`);


        /*
      ** Checks:
      ** 1) Is the current app installed
      ** 2) If installed, do we need to re-auth because of missing needed scopes. If we do, send to re-auth URL. If not, send to Shopify entry point route to get them into app
      ** 3) If not installed send them to the auth URL
      **
       */
        if (response && response.data) {
          if (this.APPID === 'BULK_EDITOR') {
            if (response.data.appInstalledBulkEditor) {
              if (response.data.authNeeded) {
              // App installed, but we need re-auth as missing certain scopes that we need for the store
              //   console.log('Embedded - Installed - Redirecting - oauth');
              //   console.log(`Embedded - Installed - Redirecting - oauth - ${oauthLoginUrl}`);

                try {
                  this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
                } catch (err) {
                  console.log(`Redirect err: ${err}`);
                }
              } else {
                // console.log('Embedded - Installed - Redirecting - main - BULKEDITOR');
                window.location = `/shopifyEntry?store=${this.state.shop}&host=${this.state.host}&embedded=true&appId=bulk_editor`;
              }
            } else {
              // console.log('Embedded - Not Installed - Redirecting - oauth');

              try {
                this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
              } catch (err) {
                console.log(`Embedded - Not Installed - Redirect err: ${err}`);
              }
            }
          } else if (this.APPID === 'ANALYTICS') {
            if (response.data.appInstalledAnalytics) {
              if (response.data.authNeeded) {
                // App installed, but we need re-auth as missing certain scopes that we need for the store
                // console.log('Embedded - Installed - Analytics - Redirecting - oauth');
                // console.log(`Embedded - Installed - Analytics - Redirecting - oauth - ${oauthLoginUrl}`);

                try {
                  this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
                } catch (err) {
                  console.log(`Redirect err: ${err}`);
                }
              } else {
                // console.log('Embedded - Installed - Redirecting - main - BULKEDITOR');
                window.location = `/shopifyEntry?store=${this.state.shop}&host=${this.state.host}&embedded=true&appId=analytics`;
              }
            } else {
              // console.log('Embedded - Not Installed - Redirecting - oauth');

              try {
                this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
              } catch (err) {
                console.log(`Embedded - Not Installed - Redirect err: ${err}`);
              }
            }
          } else if (this.APPID === 'EXPORTER') {
            if (response.data.appInstalledExporter) {
              if (response.data.authNeeded) {
              // App installed, but we need re-auth as missing certain scopes that we need for the store
              //   console.log('Exporter Embedded - Installed - Redirecting - oauth');
              //   console.log(`Exporter Embedded - Installed - Redirecting - oauth - ${oauthLoginUrl}`);

                try {
                  this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
                } catch (err) {
                  console.log(`Exporter Redirect err: ${err}`);
                }
              } else {
                // console.log('Exporter Embedded - Installed - Redirecting - main - EXPORTER');
                window.location = `/shopifyEntry?store=${this.state.shop}&host=${this.state.host}&embedded=true&appId=exporter`;
              }
            } else {
              // console.log('Exporter Embedded - Not Installed - Redirecting - oauth');

              try {
                this.redirect.dispatch(Redirect.Action.REMOTE, oauthLoginUrl);
              } catch (err) {
                console.log(`Exporter Embedded - Not Installed - Redirect err: ${err}`);
              }
            }
          } else {
            console.log('Bad app id');
          }
        }
      } catch (err) {
        console.log(`axios error: ${err}`);
      }
    } else {
      // Go to oauth
      // console.log('Not Embedded - Redirecting - oauth');
      window.top.location = oauthLoginUrl;
    }
  }


  render() {
    return (<div style={{ padding: '20px' }}>
      {/* Shopify login ...*/}
    </div>);
  }
}

export default ShopifyLogin;
