import axios from 'axios';
import pako from 'pako';
import AuthService from './AuthService';

class AxiosClient {
  static instance = null;

  // The constructor now accepts parameters
  constructor() {
    // console.log('AxiosClient constructor');
    if (!AxiosClient.instance) {
      // create
      this.axiosClient = axios.create({ timeout: 1000 * 60 * 60 }); // Create custom Axios client, with a large timeout
      // console.log('AxiosClient axios created');

      // Add a request interceptor to the custom Axios instance
      this.axiosClient.interceptors.request.use(async (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.Token = await AuthService.getInstance().getToken();
        // eslint-disable-next-line no-param-reassign
        config.headers.appid = await AuthService.getInstance().appId;

        if (config.method === 'post' && config.data && typeof config.data === 'object') {
          const jsonString = JSON.stringify(config.data);
          // Check if JSON string size is larger than 4KB before compressing
          if (Buffer.byteLength(jsonString, 'utf8') > 4096) {
            // console.log(`Original payload size: ${Buffer.byteLength(jsonString, 'utf8')} bytes`);

            // eslint-disable-next-line no-param-reassign
            config.data = pako.gzip(jsonString, { to: 'string', level: 9 });
            // eslint-disable-next-line no-param-reassign
            config.headers['Content-Encoding'] = 'gzip';
            // eslint-disable-next-line no-param-reassign
            config.headers['Content-Type'] = 'application/json';
          }
        }

        return config;
      }, (error) => {
        console.log(`axios intercept error: ${error}`);
      });

      AxiosClient.instance = this;
    }
    return AxiosClient.instance;
  }


  /**
   * Returns the singleton instance
   * @returns {null}
   */
  static getInstance() {
    // console.log('AxiosClient.getInstance');

    if (!AxiosClient.instance) {
      // console.log('AxiosClient needs to create instance');
      AxiosClient.instance = new AxiosClient();
    }
    // console.log('AxiosClient getInstance has existing');
    return AxiosClient.instance.axiosClient;
  }
}

export default AxiosClient;
