@import "../../styles/variables";

.component {
  .introduction {
    a {
      font-weight: 600;
    }
  }

  .warning {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: @background-base;
  }

  .workspaceInformation {
    padding: 30px 0 30px 0;
  }

  .connectedStores {
    .store {
      border: 1px solid @border-color-1;
      border-radius: 2px;
      padding: 20px;
      margin-bottom: 10px;

      .name {
        font-weight: 600;
      }

      .subscriptionStatus {
        padding: 10px 0 10px 0;

        .upgrade {
          padding-top: 5px;
          padding-bottom: 15px;
        }
      }

      .buttons {
        margin-top: 7px;

        div {
          margin-bottom: 5px;
        }
      }
    }
  }

}
