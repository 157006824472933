@import "../../../../styles/variables";

.component {
  margin-bottom: 50px;
  .connectedStoresContainer {
    .header {
      margin-bottom: 20px;

      h2 {
        font-size: 16px;
      }
    }

    .stores {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .store {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        //border: 1px solid #ccc;
        //border-radius: 5px;

        .storeName {
          text-align: left;
          font-size: 16px;
        }

        .plan {
          text-align: right;
          font-size: 16px;
          font-style: italic;
        }
      }
    }

    .connectShopifyStoreButton {
      background: white;
      border: 1px solid #eeeef2;
      box-shadow: none;
      padding: 10px;
      margin-top: 10px;
      border-radius: @border-radius;

      &:hover {
        box-shadow: 0 4px 30px rgba(48,48,54,.04);
      }
    }
  }
}
