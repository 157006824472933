[
  {
    "title": "Bulk edit your Shopify store’s data",
    "description": "The basic principles on how to use Mixtable to bulk edit your Shopify store's data",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/bulk-edit-shopify-store-data/"
  },
  {
    "title": "Edit Metafields",
    "description": "How to add Metafields to your workbook, and how to use Mixtable to edit them",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/bulk-edit-shopify-metafields/"
  },
  {
    "title": "Track and modify inventory",
    "description": "Manage your store's inventory across multiple locations",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/shopify-inventory-management-and-tracking/"
  },
  {
    "title": "Add product category",
    "description": "Set a product category for your products individually or in bulk",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/add-product-category-in-shopify/"
  },
  {
    "title": "Set international market prices",
    "description": "Set fixed international prices for your products in bulk",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/set-shopify-international-pricing/"
  },
  {
    "title": "Share a workbook",
    "description": "Give others granular access to your Mixtable workbook",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/share-workbook-with-shopify-data/"
  }
]
