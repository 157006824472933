import React from 'react';
import Config from '../../config';
import AuthService from '../../lib/AuthService';

/**
 * Component starts the login process for the user
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      API_URL: Config.API_URL,
    };
  }

  render() {
    if (typeof window !== 'undefined') {
      AuthService.getInstance().login();
    }

    return (
      <div>
        {/* Login*/}
      </div>
    );
  }
}


export default Login;

