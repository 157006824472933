import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import asyncJS from 'async';
import { Button, Callout, Classes, Dialog, Intent } from '@blueprintjs/core';
import * as styles from './addShopifyLinkedWorkbookDialog.module.less';
import API from '../../../../config';
import Logger from '../../../../models/logger';
import blankWorkbookImage from '../../../../assets/images/new-workbook-empty.png';
import templateWorkbookImage from '../../../../assets/images/new-workbook-template.png';
import AxiosClient from '../../../../lib/AxiosClient';

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: '39px',
    background: '#f8f7f7',
    fontWeight: '600',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '39px',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
};

/**
 * A dialog that allows the user to add a new Workbook
 */
class AddShopifyLinkedWorkbookDialog extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {
      closeAddShopifyWorkbookDialog: props.closeAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };

    /* Create our logger */
    this.logger = new Logger('AddShopifyLinkedWorkbookDialog');

    this.state = {
      storesSubscriptions: props.storesSubscriptions || [],
      selectedShopifyStore: null,
      connectedShopifyStores: [],
      connectedShopifyStoresSelectOptions: [],
      shopifyStoresStillDownloadingData: [], // List of store urls that are still doing their initial data download into Mixtable
      working: false,
    };
  }

  /** Sets the stores select options */
  setStoresSelectOptions = () => {
    const connectedShopifyStores = this.state.storesSubscriptions ? Object.keys(this.state.storesSubscriptions) : [];
    const connectedShopifyStoresSelectOptions = connectedShopifyStores.map(store => ({ value: store, label: store }));

    this.setState({
      connectedShopifyStores,
      connectedShopifyStoresSelectOptions,
      selectedShopifyStore: connectedShopifyStores[0] || null,
    });
  }

  /* Checks if all our stores have completed their initial data downloads */
  checkIfInitialStoreDownloadHasCompleted = async () => {
    const newStoresStillDownloadingData = [];

    await asyncJS.eachLimit(this.state.connectedShopifyStores || [], 1, async (storeURL) => {
      try {
        const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${storeURL}/downloadStatus/`);

        // console.log(`checking ${storeURL} - response.data: ${JSON.stringify(response.data)}`);

        // If we don't have a response.data, mark store as needs downlad
        if (!response.data) {
          // console.log(`adding ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        } else if (JSON.stringify(response.data).indexOf('in-progress') > -1) {
          // Check if any entry is "downloading". Do a test by stringifying the JSON and trying to find the "in-progress" string. Simpler than iterating over all the fields
          // and somewhat future-proof in case we decide to add new fields

          // console.log(`adding (2) ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        }
      } catch (err) {
        this.logger.error('checkIfInitialStoreDownloadHasCompleted', `Error getting sync task count for ${storeURL}: ${err}`);

        /* Show the error to the user */
        // this.parentFunctions.showError(err);
      } finally {
      }
    });

    this.setState({
      shopifyStoresStillDownloadingData: newStoresStillDownloadingData,
    }); // Update the state with the list of stores still downloading data
  }

  async componentDidMount() {
    await this.setStoresSelectOptions();
    await this.checkIfInitialStoreDownloadHasCompleted();
  }

  render() {
    return (
      <Dialog
        isOpen={true}
        onClose={this.parentFunctions.closeAddShopifyWorkbookDialog}
        icon="new-layer"
        title="Create a workbook - How do you want to start?"
        style={{ width: 'fit-content' }}
        {...this.state}>
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.component}>
            <div className={styles.left}>
              <div className={styles.thumbnail}>
                <img src={blankWorkbookImage}/>
              </div>

              <div className={styles.content}>
                <div className={styles.title}>
                  Start from scratch
                </div>

                <div className={styles.description}>
                  Create the same, blank workbook that you get in Excel. Best place to start if you are an advanced user and know exactly what Shopify worksheets you'd like to add.
                </div>

                <div className={styles.emptyPlaceholder}></div>

                <div className={styles.createWorkbookButton}>
                  <Button
                    large={false}
                    intent={Intent.PRIMARY}
                    onClick={async () => {
                      this.parentFunctions.createWorkbook('basic', null);
                    }}
                    text={'Start here'}
                    minimal={false}
                    fill={true}
                    alignText={'center'}
                    loading={this.state.working}
                  />
                </div>

              </div>


            </div>

            <div className={styles.right}>
              <div className={styles.thumbnail}>
                <img src={templateWorkbookImage}/>
              </div>

              <div className={styles.content}>
                <div className={styles.title}>
                  Start with our Shopify template
                </div>

                <div className={styles.description}>
                  Start with our Shopify store template workbook. From the start get worksheets for the data our users most often need, with each worksheet having the most commonly needed columns. You can, of course, add/remove data as you need, but this workbook is a great starting place for most people.
                </div>

                <div className={styles.selectStore}>
                  {/* {this.state.shopifyStoresStillDownloadingData.length > 0 && this.state.shopifyStoresStillDownloadingData.indexOf(this.state.selectedShopifyStore) > -1 && <div className={styles.callout}> */}
                  {/*  /!* Inform the user that selected store is still downloading data *!/*/}
                  {/*  <Callout intent={Intent.WARNING} title={'Store data is still downloading'}>*/}
                  {/*    The data for <strong>{this.state.selectedShopifyStore}</strong> is still downloading from Shopify. Please give it time to finish before creating a Shopify-linked workbook.*/}
                  {/*  </Callout>*/}
                  {/* </div>} */}

                  {/* You still need to add a store*/}
                  {this.state.connectedShopifyStores.length === 0 &&
                    <Callout intent={Intent.WARNING} style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: '45px' }}>
                      <div>You need to connect a Shopify store to Mixtable</div>
                    </Callout>
                  }

                  {/* Dropdown showing list of stores*/}
                  {this.state.connectedShopifyStores.length > 0 && <div>
                    {/* Shopify store selector - show if we have more than one store */}
                    {this.state.connectedShopifyStores.length > 1 && <div style={{ marginBottom: '10px' }}>
                      <Select
                        styles={customSelectStyles}
                        placeholder={'Select a Shopify store'}
                        value={_.find(this.state.connectedShopifyStoresSelectOptions, { value: this.state.selectedShopifyStore })}
                        onChange={((filterValue) => {
                          this.setState({
                            selectedShopifyStore: filterValue.value,
                          });
                        })}
                        options={this.state.connectedShopifyStoresSelectOptions}
                      />
                    </div>}
                  </div>}
                </div>

                <div className={styles.createWorkbookButton}>
                  <Button
                    large={false}
                    intent={Intent.PRIMARY}
                    onClick={async () => {
                      this.parentFunctions.createWorkbook('shopify_starter', this.state.selectedShopifyStore);
                    }}
                    text={'Start here'}
                    minimal={false}
                    fill={true}
                    alignText={'center'}
                    loading={this.state.working}
                    disabled={!this.state.selectedShopifyStore}
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddShopifyLinkedWorkbookDialog;
