import React from 'react';
import Helmet from 'react-helmet';
import asyncJS from 'async';
import _ from 'lodash';
import { Intent, OverlayToaster, Position, Toast2 } from '@blueprintjs/core';
import * as styles from './home.module.less';
import API from '../config';
import IntegrationsCatalog from '../components/integrationsCatalog';
import Logger from '../models/logger';
import WorkbooksPage from '../components/homeComponents/workbooksPage';
import Sidebar from '../components/homeComponents/sidebar';
import LoadingAnimation from '../components/loadingAnimation';
import AxiosClient from '../lib/AxiosClient';
import AuthService from '../lib/AuthService';
import AppToaster from '../lib/Toaster';

class HomePage extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      initialLoadingDone: false,
      masterEditions: {},
      workbooks: [],
      storesSubscriptions: {},
      currentScreen: 'workbooks',
      toasts: [],
    };

    // Store the Auth provider
    this.authProvider = AuthService.getInstance().provider;

    /* Set up the toaster */
    this.toaster = AppToaster;
    this.refHandlers = {
      toaster: ref => this.toaster = ref,
    };

    /* Create our logger */
    this.logger = new Logger('HomePage');
  }

  async componentDidMount() {
    try {
      /* Get all workspaces */
      const workspaces = await this.getWorkspaces();

      /* Check if we have any workspaces */
      if (!workspaces || workspaces.length <= 0) {
        this.showError('No workspaces available');
        return;
      }

      /* Get the current workspace */
      const currentWorkspace = workspaces[0];

      /* Now when we are sure that we have all workspaces get all the integration configs for the first workspace */
      const storesSubscriptions = await this.getWorkspaceShopifyStoresSubscriptions(currentWorkspace.id);

      /* Finally, load all user workbooks */
      await this.loadUserWorkbooks();

      this.setState({
        workspaces,
        currentWorkspace,
        storesSubscriptions,
        initialLoadingDone: true,
      });
    } catch (err) {
      this.logger.error(`Error while loading home page: ${JSON.stringify(err)}`);
    }
  }

  /**
   * Shows a regular, status-update toast
   */
  showToast = (toastData) => {
    this.toaster.show({
      message: toastData.message,
      icon: toastData.icon,
      intent: toastData.intent,
    });
  }

  /**
   * Display an error Toast2 to the user (copy of the one from spreadsheet component)
   * @param err
   */
  showError = (err) => {
    const errorMessage = err.response && err.response.data && err.response.data.message ? err.response.data.message : err.toString();

    this.toaster.show({
      message: errorMessage,
      icon: 'issue',
      intent: Intent.DANGER,
    });
  }

  /**
   * Loads all workspaces user has
   * @returns {Promise<*[]>}
   */
  getWorkspaces = async () => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workspaces/`);

      return response.data;
    } catch (err) {
      this.logger.error('Home - componentDidMount', `Error loading workspace: ${err}`);

      this.showError(err);
    }
    return [];
  }

  /**
   * Load the workbooks that the user has access to
   * @returns {Promise<void>}
   */
  loadUserWorkbooks = async () => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workbooks/`);

      const workbooks = response.data; // Workbooks we received
      const workbooksToSave = []; // Workbooks we are going to show
      const masterEditions = this.state.masterEditions || {};

      await asyncJS.eachLimit(workbooks, 2, async (workbook) => {
        /* Create a copy of the workbook because we are going to modify it */
        const workbookObject = JSON.parse(JSON.stringify(workbook));

        const masterEdition = await this.getWorkbookMasterEdition(workbook);

        if (!masterEdition) {
          this.logger.error('loadUserWorkbooks', `Error loading user workbooks no master edition for ${workbook.id}`);
          // this.showError(`No master edition for ${workbook.name}`);
          return;
        }
        /* Save the master edition */
        masterEditions[workbook.id] = masterEdition;

        /* Get all the stores connected to edition worksheets */
        const stores = this.getStoresConnectedToWorkbookEdition(masterEdition);
        workbookObject.stores = stores || [];

        workbooksToSave.push(workbookObject);
      });

      /* Save the workbooks, master editions and stores */
      this.setState({
        workbooks: workbooksToSave,
        masterEditions,
      });
    } catch (err) {
      this.logger.error('loadUserWorkbooks', `Error loading user workbooks: ${err}`);

      this.showError(err);
    }
  }

  /**
   * Gets the master edition of a given workbook
   * @param workbook
   */
  getWorkbookMasterEdition = async (workbook) => {
    try {
      const responseWorkbookMaster = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workbooks/${workbook.id}/master`);

      return responseWorkbookMaster.data;
    } catch (err) {
      console.log(`Error loading workbook master: ${err}`);

      this.showError(err);
    }
    return null;
  }

  /**
   * Helper method to extract the stores within edition worksheets
   * @returns {any[]}
   * @param workbookEdition
   */
  getStoresConnectedToWorkbookEdition = (workbookEdition) => {
    const worksheets = workbookEdition.worksheets;

    const stores = [];

    worksheets.forEach((worksheet) => {
      const store = _.get(worksheet, 'metadata.dataSource.shopify.store');
      if (store && !stores.includes(store)) {
        stores.push(store);
      }
    });

    return stores;
  }

  /**
   * Get mixtable last subscription for each workspace store
   * @param workspaceId
   * @returns {Promise<any|*[]>}
   */
  getWorkspaceShopifyStoresSubscriptions = async (workspaceId) => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/billing/shopify/${workspaceId}/getWorkspaceShopifyStoresSubscriptions`);

      return response.data;
    } catch (err) {
      this.logger.error(`Error in getWorkspaceIntegrationConfig ${JSON.stringify(err)}`);
      this.showError(err);
    }
    return [];
  }

  openIntegrationsCatalogScreen = () => {
    this.setState({ currentScreen: 'integrationsCatalog' });
  };

  openWorkbooksScreen = () => {
    this.setState({ currentScreen: 'workbooks' });
  };

  render() {
    return (
      <div className={styles.component}>
        <Helmet>
          <title>Mixtable</title>
          <meta property="og:title" content={'Mixtable'}/>
          <meta property="og:url" content={'https://www.mixtable.com/'}/>
        </Helmet>

        {/* Toast component*/}
        <OverlayToaster position={Position.BOTTOM} ref={this.refHandlers.toaster}>
          {/* "Toasted!" will appear here after clicking button. */}
          {this.state.toasts.map((toast, index) => <Toast2 key={index} {...toast} />)}
        </OverlayToaster>

        {!this.state.initialLoadingDone && <div className={styles.loadingAnimationContainer}>
          <LoadingAnimation />
        </div>}

        {/* Sidebar */}
        {this.authProvider !== 'shopify' && this.state.initialLoadingDone && this.state.currentWorkspace && <Sidebar
          currentWorkspace={this.state.currentWorkspace}
          openWorkbooksScreen={this.openWorkbooksScreen}
          openIntegrationsCatalogScreen={this.openIntegrationsCatalogScreen}
        />}

        {/* Workbooks page */}
        {this.state.initialLoadingDone && this.state.currentScreen === 'workbooks' && <div className={[styles.contentContainer, (this.authProvider === 'shopify' ? styles.noSidebar : '')].join(' ')}>
          {/* We have user workbooks to display*/}
          {this.state.workbooks && <WorkbooksPage
            showError={this.showError}
            workspaceId={this.state.currentWorkspace.id}
            workbooks={this.state.workbooks}
            masterEditions={this.state.masterEditions}
            storesSubscriptions={this.state.storesSubscriptions}
            openIntegrationsCatalogScreen={this.openIntegrationsCatalogScreen}
          />}
        </div>}

        {/* Integration Catalogs Screen */}
        {this.state.initialLoadingDone && this.state.currentScreen === 'integrationsCatalog' && <div className={styles.contentContainer}>
          <h3 className={styles.sectionHeading}>Integrations</h3>
          {this.state.workbooks && <IntegrationsCatalog showToast={this.showToast} showError={this.showError} workspace={this.state.currentWorkspace}/>}
        </div>}
      </div>
    );
  }
}

export default HomePage;
