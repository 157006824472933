import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AppConfig from '../src/config/index';
import App from './App';

if (module.hot) {
  module.hot.accept();
}

Sentry.init({
  dsn: AppConfig.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  release: process.env.SENTRY_RELEASE_VERSION, // set the Release

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
