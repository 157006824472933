import React from 'react';

import Routes from './routes';
import GoogleTagManager from './components/googleTagManager';

import './styles/reactStyles.css';
import './styles/blueprintjs/blueprint.css';
import './styles/blueprintjs/blueprint-datetime2.css';
import './styles/image-editor.css';
import './assets/fonts/Inter/inter.css';
import './assets/fonts/icomoon/icomoon.css';
import './styles/app.less';

const App = () => <div>
  <GoogleTagManager gtmId={'GTM-N83LJP7'}/>
  <Routes/>
</div>;

export default App;
