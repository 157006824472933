import React, { Component } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import * as styles from './header.module.less';

/**
 * The Header Component within the Home Page
 */
class Header extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openAddShopifyWorkbookDialog: props.openAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };

    this.state = {
      hasWorkbooks: props.hasWorkbooks,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles.component}>
        {/* If user is new and do not have workbooks just show welcome back message */}
        {!this.state.hasWorkbooks && <div className={styles.headerContainer}>
          <div className={styles.welcomeMessage}>
            <h1>Welcome to Mixtable</h1>
          </div>
        </div>}

        {/* If user has workbooks show him the simplified version of create workbook buttons and different welcome message */}
        {this.state.hasWorkbooks && <div className={styles.headerContainer}>
          {/* <div className={styles.right}>*/}
          <div className={styles.welcomeMessage}>
            <h1>Welcome back</h1>
          </div>
          {/* </div>*/}

          <div className={styles.left}>
            <div className={styles.createWorkbookButtons}>
              <Button
                intent={Intent.PRIMARY}
                minimal={false}
                outlined={false}
                alignText={'center'}
                onClick={this.parentFunctions.openAddShopifyWorkbookDialog}
                text={'Create a workbook'}
              />
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default Header;
